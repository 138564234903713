import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64 px;
    padding-bottom: 0 px;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }

  #postfullembed {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
  }
`;

const Periodictable: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>Christmas Lyrics Emotion Detector</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
    
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      
      <main id="site-main" className="site-main" css={[SiteMain]}> {/*SiteMain,outer*/}
        <PostFullHeader className="post-full-header">
          <PostFullTitle className="post-full-title">Christmas Lyrics Emotion Detector</PostFullTitle>
          <div>Click here for more articles on <a href="/">innerdoc.com</a>.</div>
        </PostFullHeader>
        
        <PostFullContent id="postfullembed" className="post-full-content">
          <iframe src='https://share.streamlit.io/innerdoc/christmas-lyrics-emotion-detector/main/christmas_lyrics_emotion_detector.py' height="1000"  width="100%"></iframe>
        </PostFullContent>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default Periodictable;
